@import url(https://fonts.googleapis.com/css?family=Montserrat:100,200,300,regular,500,600,700,800,900,100italic,200italic,300italic,italic,500italic,600italic,700italic,800italic,900italic);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,regular,500,600,700,800,300italic,italic,500italic,600italic,700italic,800italic);
@import url(https://fonts.googleapis.com/css?family=Lato:100,100italic,300,300italic,regular,italic,700,700italic,900,900italic);
@import url(https://fonts.googleapis.com/css?family=Raleway:100,200,300,regular,500,600,700,800,900,100italic,200italic,300italic,italic,500italic,600italic,700italic,800italic,900italic);

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
  overflow-x: hidden;
}

body {
  scroll-behavior: smooth;
  /* background-color: #F5F5F5; */
}

.header {
  max-width: 130rem;
  margin: 0 auto;
  padding: 2.4rem;
}

main {
  margin-bottom: 9.4rem;
}

section {
  max-width: 130rem;
  margin: 0 auto;
  padding: 2.4rem;
  margin-bottom: 9.6rem;
}

.hero {
  background: rgb(210, 229, 247);
  background: linear-gradient(
    180deg,
    rgba(210, 229, 247, 1) 0%,
    rgba(239, 247, 255, 1) 60%,
    rgba(255, 255, 255, 1) 100%
  );
}

.custom-btn {
  @apply bg-accent-color-1 font-semibold text-white rounded-full px-[2rem] py-[0.8rem] text-[1.6rem] h-[4.0rem] flex items-center justify-center hover:shadow-md shadow-blue-100 transition-all duration-300 ease-in-out;
}

.form-input {
  @apply p-[1.6rem] shadow-md w-full text-[1.6rem] font-opensans rounded-[12px] text-body-text focus:border-background outline-none bg-[#fefefe];
}

@keyframes slide-in {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes slide-out {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(100%);
  }
}

.animate-slide-in {
  animation: slide-in 0.5s ease-out;
}

.animate-slide-out {
  animation: slide-out 0.5s ease-out;
}

/* queries */

@media (max-width: 1024px) {
  html {
    /* 9px/16px = 56.25 */
    font-size: 56.25%;
  }
}

@media (max-width: 768px) {
  html {
    /* 8px/16px = 50% */
    font-size: 50%;
  }
}

/* @media (max-width: 480px) {
  html {
    /* 7px/16px = 50% 
    font-size: 43.75%;
  }
}

@media (max-width: 240px) {
  html {
    /* 6px/16px = 50% 
    font-size: 37.5%;
  }
} */
